import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ScaleLoader } from 'react-spinners';
import '../Assets/Styles/App.scss';


const LandingPage = React.lazy(() => import('../Pages/LandingPage/LandingPage'));
const OurServices = React.lazy(()=>import('../Pages/AlumniConnect/OurServices/OurServices'));
const Benefits=React.lazy(()=>import('../Pages/AlumniConnect/PartnershipBenefits/PartnershipBenefits'));
const CriterialForInstitutionalPartners=React.lazy(()=>import('../Pages/AlumniConnect/CriterialForInstitutionalPartners/CriterialForInstitutionalPartners'));
const ContactUs = React.lazy(()=>import('../Pages/AlumniConnect/ContactUs/ContactUs'));
const ApplyToJoin = React.lazy(()=>import('../Pages/AlumniConnect/ApplyToJoin/ApplyToJoin'));
const TermsofContract = React.lazy(() => import('../Pages/AlumniConnect/Legal/TermsofContract/TermsofContract'));
const TermsAndConditionsOfUsers = React.lazy(() => import('../Pages/AlumniConnect/Legal/TermsAndConditionsOfUsers/TermsAndConditionsOfUsers'));
const DataAndPrivacyPolicy = React.lazy(() => import('../Pages/AlumniConnect/Legal/DataAndPrivacyPolicy/DataAndPrivacyPolicy'));
const CommunityGuidelines = React.lazy(() => import('../Pages/AlumniConnect/Legal/CommunityGuidelines/CommunityGuidelines'));
const CategoriesOfInstitutionalPartners=React.lazy(()=>import('../Pages/AlumniConnect/CategoriesOfInstitutionalPartners/CategoriesOfInstitutionalPartners'));
const Pricing=React.lazy(()=>import('../Pages/AlumniConnect/Pricing/Pricing'));
const Legal = React.lazy(() => import('../Pages/AlumniConnect/Legal/Legal'));
const PageNotFound = React.lazy(() => import('../Pages/PageNotFound/PageNotFound'));



function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div className="loader-container"><ScaleLoader
          height={35} width={4} radius={2}
          margin={2} color="#7b2cbf" /></div>}>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/company/services' element={<OurServices/>}/>
            <Route path='/company/benefits' element={<Benefits/>}/>
            {/* Remove content bellow line 35 and realted file */}
            <Route path='/company/criterial' element={<CriterialForInstitutionalPartners/>}/>
            <Route path='/company/contactus' element={<ContactUs/>}/>
            <Route path='/alumniconnect/legal/' element={<Legal />} />
            <Route path='/company/legal/TermsofContract' element={<TermsofContract/>}/>
            <Route path='/company/legal/TermsAndConditionsOfUsers' element={<TermsAndConditionsOfUsers/>}/>
            {/* Remove content bellow line 35 and realted file */}
            <Route path='/company/legal/privacy' element={<DataAndPrivacyPolicy/>}/>
             {/* Remove content bellow line 35 and realted file */}
            <Route path='/company/legal/guidelines' element={<CommunityGuidelines/>}/>
            <Route path='/company/princing' element={<Pricing/>}/>
            <Route path='/institutions/apply' element={<ApplyToJoin/>}/>
            <Route path='/categories' element={<CategoriesOfInstitutionalPartners/>}/>
            <Route path="*" element={<PageNotFound />} />

           
         

          </Routes>
        </Suspense>
      </Router>


    </div>
  );
}

export default App;
